import { ApiManager } from './apiUtils';

const apiManager = new ApiManager();

const API_SURVEY_ENDPOINT = `${process.env.REACT_APP_API_ENDPONT}/rest/survey`;
const API_SAVE_CONVERSATION_ENDPOINT = `${process.env.REACT_APP_API_ENDPONT}/rest/survey/conversation`;

export function getSurvey(params) {
  const { toolSlug, surveySlug, surveyKey } = params;

  const url = `${API_SURVEY_ENDPOINT}/${toolSlug}/${surveySlug}/${surveyKey}`;
  return apiManager.get(url).then((data) => data);
}

export function postCreateSurvey(body) {
  const url = API_SURVEY_ENDPOINT;
  return apiManager.post(url, body).then((data) => data);
}

export function postSaveConversation(conversation) {
  const body = {
    key: conversation.result.survey.key,
    toolSlug: conversation.result.tool.slug,
    surveySlug: conversation.result.survey.slug,
    moderator: conversation.moderator,
    participants: conversation.participants,
    results: conversation.result.questionnaire.map((question) => {
      return {
        theme: question.theme.id,
        topic: question.topic.id,
        question: question.id,
        answer: question.answer || '',
      };
    }),
  };

  const url = API_SAVE_CONVERSATION_ENDPOINT;
  return apiManager.post(url, body).then((data) => data);
}
