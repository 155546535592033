import * as types from '../actions/actionTypes';

const initialState = {
  data: null,
  pending: false,
  error: null,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_DASHBOARD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
        error: null
      };
    case types.FETCH_DASHBOARD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
