// react
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';

// components
import PieChart from './PieChart';
import BarChart from './BarChart';
import ButtonLink from './ButtonLink';
import Button from './Button';

// assets
import { ReactComponent as MinusIcon } from '../assets/images/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../assets/images/icons/plus.svg';

function findConversationById(conversations, id) {
  return conversations.filter((conversation) => conversation.id === id).map((conversation) => conversation)[0];
}

function convertUnixTimestampToDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

function orderByDate(a, b) {
  return b.date - a.date;
}

const OpenAnswers = ({ question, conversations, topicRef }) => {
  const [collapse, setCollapse] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(4);

  const answers = question.answers
    .map((answer) => {
      const conversation = findConversationById(conversations, answer.id);

      return {
        date: conversation.date,
        moderator: conversation.moderator,
        participants: conversation.participants,
        answer: answer.answer,
      };
    })
    .sort(orderByDate);

  const handleCollapse = (event) => {
    event.preventDefault();
    event.currentTarget.blur();

    setCollapse(!collapse);
    setItemsToShow(collapse ? 4 : answers.length);

    // scroll to top of element after closing it
    if (collapse) {
      window.scrollTo(0, topicRef.current.offsetTop - 30);
    }
  };

  return (
    <React.Fragment>
      <div className={`c-dashboard-open-answers ${collapse ? 'is-open' : ''}`}>
        {answers.slice(0, itemsToShow).map((answer, index) => {
          const date = convertUnixTimestampToDate(answer.date);
          if(!answer.answer.length) return;
          return (
            <div key={index} className="c-dashboard-open-answer">
              <strong className="c-dashboard-open-answer__info">
                {date} - {answer.moderator}, {answer.participants}
              </strong>
              <p className="c-dashboard-open-answer__text">{answer.answer}</p>
            </div>
          );
        })}
        {answers.length > 4 && !collapse && <div className="c-dashboard-open-answers__overlap"></div>}
      </div>
      {answers.length > 4 && (
        <ButtonLink onClick={handleCollapse}>
          {collapse ? (
            <React.Fragment>
              <MinusIcon className="c-minus-icon" />
              <span>Lees minder</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <PlusIcon className="c-plus-icon" />
              <span>Lees meer</span>
            </React.Fragment>
          )}
        </ButtonLink>
      )}
    </React.Fragment>
  );
};

const TabContent = ({ theme, conversations }) => {
  return (
    <React.Fragment>
      {theme.topics.map((topic, index) => {
        const topicRef = useRef();

        return (
          <div ref={topicRef} key={index} className="c-dashboard-topic-block">
            <h2 className="c-dashboard-topic-block__title">{topic.title}</h2>
            {topic.questions.map((question, index) => {
              return (
                <div key={index} className="c-dashboard-question-block">
                  <h2 className="c-dashboard-question-block__title">{question.question}</h2>
                  {
                    {
                      open: <OpenAnswers topicRef={topicRef} question={question} conversations={conversations} />,
                      yes_no: <PieChart question={question} />,
                      multiple_choice: <BarChart question={question} />,
                    }[question.type]
                  }
                </div>
              );
            })}
          </div>
        );
      })}
    </React.Fragment>
  );
};

const DashboardTabsComponent = ({ dashboard }) => {
  const [activeTab, setActiveTab] = useState('tab-0');
  const history = useHistory();

  const handleTabsNav = (event, tabId) => {
    event.preventDefault();
    event.currentTarget.blur();
    setActiveTab(tabId);
  };

  const handleGoToUrl = (event, url) => {
    event.preventDefault();
    event.currentTarget.blur();
    history.push(url);
  };

  const themes = dashboard.questionnaire;

  return (
    <section className="c-dashboard-tabs">
      <div className="c-tabs-nav">
        <div className="o-container">
          <ul className="c-tabs-nav__list" role="tablist">
            {themes.map((theme, index) => {
              const tabId = `tab-${index}`;
              const tabNavId = `tab-nav-${index}`;
              const isActive = activeTab === tabId;
              return (
                <li key={index} className={`c-tabs-nav__list-item ${isActive ? 'is-active' : ''}`}>
                  <button
                    className="c-tabs-nav__list-item-link"
                    id={tabNavId}
                    role="tab"
                    aria-controls={tabId}
                    aria-selected={isActive ? 'true' : 'false'}
                    type="button"
                    onClick={(event) => handleTabsNav(event, tabId)}
                  >
                    {theme.title}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {themes.map((theme, index) => {
        const tabId = `tab-${index}`;
        const tabNavId = `tab-nav-${index}`;
        const isActive = activeTab === tabId;
        return (
          <div
            key={index}
            className={`c-tabs-content theme ${isActive ? 'is-active' : ''}`}
            id={tabId}
            aria-labelledby={tabNavId}
          >
            <div className="o-container">
              <TabContent theme={theme} conversations={dashboard.conversations} />
            </div>
          </div>
        );
      })}
      <div className="c-cta-blocks">
        <div className="o-container">
          <div className="c-cta-blocks__row">
            <div className="c-cta-blocks__col">
              <div className="c-cta-block">
                <h3 className="c-cta-block__title">Ga in gesprek met jouw gemeente of team.</h3>
                <Button
                  style="white"
                  label="Plan een ander gesprek"
                  arrow="right"
                  onClick={(event) => handleGoToUrl(event, '/')}
                />
              </div>
            </div>
            <div className="c-cta-blocks__col">
              <div className="c-cta-block">
                <h3 className="c-cta-block__title">Meer resultaten voor jouw gemeente of team?</h3>
                <Button
                  style="white"
                  label="Doe het gesprek nog een keer"
                  arrow="right"
                  onClick={(event) =>
                    handleGoToUrl(
                      event,
                      `/gesprekshulp/${dashboard.tool.slug}/${dashboard.survey.slug}/${dashboard.survey.key}`
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardTabsComponent;
