export class ApiManager {
  constructor(options = {}) {
    this.config = {
      ...options,
    };
  }

  post(url, data) {
    return this.request('POST', url, data);
  }

  get(url, accept = null) {
    return this.request('GET', url, null, accept);
  }

  patch(url, data) {
    return this.request('PATCH', url, data);
  }

  delete(url, data) {
    return this.request('DELETE', url, data);
  }

  request(method, url, body, accept) {
    const { lang } = document.documentElement;

    // Default headers
    const headers = new Headers({
      'Accept-Language': lang.length ? lang.toLowerCase() : 'nl',
      'Content-Type': 'application/json',
    });

    if (accept) {
      headers.append('Accept', accept);
    }

    const options = {
      method,
      cache: 'no-cache',
      mode: 'cors',
      headers,
    };

    if (body) {
      options.body = JSON.stringify(body); // stringify all data variables
    }

    // handle response, format in contentType and return to fetch API call
    const handleResponse = (response) => {
      if (response.ok || response.status === 409) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json();
        }
        return response.text();
      }
      if (response.status === 400) {
        const error = response.text();
        throw new Error(error);
      }
      throw new Error('Network response was not ok.');
    };

    return fetch(url, options)
      .then(handleResponse)
      .then((responseData) => responseData);
  }
}
