// react
import React, { useState } from 'react';

// components
import ButtonLink from './ButtonLink';
import CopyUrlButton from './CopyUrlButton';
import BreadCrumb from './BreadCrumb';

// assets
import { ReactComponent as LogoJogg } from '../assets/images/logo-jogg.svg';
import { ReactComponent as MinusIcon } from '../assets/images/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../assets/images/icons/plus.svg';
import { ReactComponent as IllustrationDashboard } from '../assets/images/illustration-dashboard.svg';

function convertUnixTimestampToDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

function orderByDate(a, b) {
  return b.date - a.date;
}

const DashboardHeaderComponent = ({ dashboard }) => {
  const [collapse, setCollapse] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(4);

  const conversations = dashboard.conversations.sort(orderByDate);

  const handleCollapse = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    setCollapse(!collapse);
    setItemsToShow(collapse ? 4 : conversations.length);
  };

  return (
    <section className="c-dashboard-header bg-primary u-text-white">
      <div className="o-container">
        <div className="c-dashboard-header__nav">
          <div className="c-dashboard-header__logo">
            <LogoJogg />
          </div>
          <BreadCrumb data={dashboard} />
        </div>
        <div className="c-dashboard-header__inner">
          <div className="c-dashboard-header__inner__left">
            <h1 className="c-dashboard-header__title">Aan de slag met de gespreksuitkomsten</h1>
            <CopyUrlButton
              value={`/dashboard/${dashboard.tool.slug}/${dashboard.survey.slug}/${dashboard.survey.key}`}
              label="Kopieer rapportage link"
            />
            <div className="c-conversations-list">
              <h2 className="c-conversations-list__title">De gevoerde gesprekken</h2>
              <ul className={`c-conversations-list__list ${collapse ? 'is-open' : ''}`}>
                {conversations.slice(0, itemsToShow).map((conversation, index) => {
                  const date = convertUnixTimestampToDate(conversation.date);
                  return (
                    <li className="c-conversations-list__list-item" key={index}>
                      {date}{' '}
                      <span className="c-conversations-list__list-item__names">
                        {conversation.moderator}, {conversation.participants}
                      </span>
                    </li>
                  );
                })}
              </ul>
              {conversations.length > 4 && (
                <ButtonLink style="white" onClick={handleCollapse}>
                  {collapse ? (
                    <React.Fragment>
                      <MinusIcon className="c-minus-icon" />
                      <span>Laat minder zien</span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <PlusIcon className="c-plus-icon" />
                      <span>Laat meer zien</span>
                    </React.Fragment>
                  )}
                </ButtonLink>
              )}
            </div>
          </div>
          <div className="c-dashboard-header__inner__right">
            <IllustrationDashboard className="c-dashboard-header__illustration" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardHeaderComponent;
