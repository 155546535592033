// react
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// components
import SurveyPageWrapper from '../components/SurveyPageWrapper';
import ConversationConfirm from '../components/ConversationConfirm';

const ConversationConfirmPage = () => {
  const history = useHistory();

  const { toolSlug, surveySlug, surveyKey } = useParams();
  const survey = useSelector((state) => state.survey);

  const questions = survey.result?.questionnaire || [];
  const questionsFilled = questions.filter((question) => question.answer !== undefined).length;

  useEffect(() => {
    if (survey.result) {
      if (!survey.moderator.length || !survey.participants.length) {
        history.push(`/gesprekshulp/${toolSlug}/${surveySlug}/${surveyKey}`);
      } else if (survey.result && questions.length != questionsFilled) {
        history.push(`/gesprekshulp/${toolSlug}/${surveySlug}/${questionsFilled + 1}/${surveyKey}`);
      }
    }
  }, []);

  return (
    <SurveyPageWrapper background center>
      <ConversationConfirm survey={survey} />
    </SurveyPageWrapper>
  );
};

export default ConversationConfirmPage;
