// react
import React from 'react';
import { useHistory } from 'react-router';

// components
import Button from './Button';
import BreadCrumb from './BreadCrumb';
import CopyUrlButton from './CopyUrlButton';

const ConversationFinish = ({ result }) => {
  if (!result) return null;

  const history = useHistory();

  const handleNext = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    history.push(`/dashboard/${result.tool.slug}/${result.survey.slug}/${result.survey.key}`);
  };

  return (
    <div className="c-conversation-finish">
      <BreadCrumb data={result} />
      <div className="u-text-center u-text-white">
        <h1 className="c-conversation-finish__title">De rapportage staat klaar</h1>
        <p className="c-conversation-finish__text u-text-large">
          Bekijk een cumulatief overzicht van alle antwoorden uit de gesprekken die tot nu toe met deze gesprekshulp
          zijn gevoerd of kopieer de rapportage link om te delen met anderen.
        </p>
        <div className="c-btn-group c-btn-group--list">
          <Button label="Bekijk de rapportage" onClick={handleNext} style="white" arrow="right" />
          <CopyUrlButton
            value={`/dashboard/${result.tool.slug}/${result.survey.slug}/${result.survey.key}`}
            label="Kopieer rapportage link"
            link
          />
        </div>
      </div>
    </div>
  );
};

export default ConversationFinish;
