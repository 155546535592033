// react
import React from 'react';

// components
import AlertMessage from './AlertMessage';

const RadioField = ({ id, name, value, label, onChange, checked }) => {
  const isChecked = value === checked;
  return (
    <div className="c-form-radio">
      <input
        name={name}
        id={id}
        className="c-form-radio__input"
        value={value}
        type="radio"
        onChange={onChange}
        checked={isChecked}
      />
      <label className="c-form-radio__label" htmlFor={id}>
        <div className="c-form-radio__control"></div>
        {label}
      </label>
    </div>
  );
};

const defaultOptions = [
  {
    label: 'Ja',
    value: 'Ja',
  },
  {
    label: 'Nee',
    value: 'Nee',
  },
  {
    label: 'N.v.t.',
    value: 'N.v.t.',
  },
];

const RadioGroupComponent = ({ name, groupLabel, options = defaultOptions, onChange, value, error = '' }) => {
  return (
    <div className={`c-form-group c-form-group--radio ${error && 'has-error'}`}>
      <label className="c-form-label">{groupLabel}</label>
      {options.map((option, index) => {
        const key = `${name}_${index + 1}`;
        return (
          <RadioField
            name={name}
            key={key}
            id={key}
            value={option.value}
            label={option.label}
            checked={value}
            onChange={onChange}
          />
        );
      })}
      {error && <AlertMessage type="error" message={error} small />}
    </div>
  );
};

export default RadioGroupComponent;
