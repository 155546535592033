// react
import React from 'react';
import { useSelector } from 'react-redux';

// components
import ParticipantsForm from '../components/ParticipantsForm';
import SurveyPageWrapper from '../components/SurveyPageWrapper';

const ConversationParticipantsPage = () => {
  const survey = useSelector((state) => state.survey);

  return (
    <SurveyPageWrapper fullWidth container={false}>
      <ParticipantsForm survey={survey} />
    </SurveyPageWrapper>
  );
};

export default ConversationParticipantsPage;
