// react
import React from 'react';

// assets
import { ReactComponent as LogoJogg } from '../assets/images/logo-jogg.svg';

const FooterComponent = () => {
  return (
    <footer className="c-footer">
      <div className="o-container">
        <div className="c-footer__inner">
          <div className="c-footer-logo">
            <LogoJogg />
          </div>
          <ul className="c-footer-menu u-list-inline">
            <li className="u-list-inline-item">
              <a href="mailto:info@jogg.nl" className="c-footer-link">
                Email
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
