// react
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet-async';

// utils
import { randomizeArray } from '../utils';

const ThemeProvider = ({ children, selectedTheme = '', random }) => {
  const location = useLocation();
  const [themeActive, setThemeActive] = useState(false);

  const shuffledThemes = useMemo(() => {
    const themes = [
      'green-pink',
      'blue-orange',
      'yellow-purple',
      'orange-blue',
      'red-purple',
      'pink-red',
      'purple-green',
    ];

    return randomizeArray(themes);
  }, [location]);

  let theme;
  if (random) {
    theme = shuffledThemes;
  } else if (selectedTheme.length) {
    theme = selectedTheme;
  }

  useEffect(() => {
    if (theme) {
      document.body.classList.add(`t-${theme}`);
      setThemeActive(true);
    }

    return () => {
      if (theme) {
        document.body.classList.remove(`t-${theme}`);
      }
    };
  }, [theme]);

  return (
    <React.Fragment>
      {themeActive && (
        <React.Fragment>
          <Helmet>
            <link rel="apple-touch-icon" sizes="180x180" href={`${process.env.REACT_APP_URL}/assets/favicon/${theme}/apple-touch-icon.png`} />
            <link rel="icon" type="image/png" sizes="32x32" href={`${process.env.REACT_APP_URL}/assets/favicon/${theme}/favicon-32x32.png`} />
            <link rel="icon" type="image/png" sizes="16x16" href={`${process.env.REACT_APP_URL}/assets/favicon/${theme}/favicon-16x16.png`} />
            <link rel="manifest" href={`${process.env.REACT_APP_URL}/assets/favicon/${theme}/site.webmanifest`} />
            <link rel="shortcut icon" href={`${process.env.REACT_APP_URL}/assets/favicon/${theme}/favicon.ico`} />
            <meta name="msapplication-config" content={`${process.env.REACT_APP_URL}/assets/favicon/${theme}/browserconfig.xml`} />
          </Helmet>
          {children}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ThemeProvider;
