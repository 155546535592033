// react
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

// actions
import * as surveyActions from '../actions/surveyActions';

// components
import SplitPane from './SplitPane';
import Button from './Button';
import Tag from './Tag';
import BreadCrumb from './BreadCrumb';
import QuestionFormField from './QuestionFormField';

const SurveyQuestion = ({ result }) => {
  if (!result) return null;

  const dispatch = useDispatch();
  const history = useHistory();

  const { questionSlug } = useParams();

  const questions = result.questionnaire;
  const currentQuestionNumber = Number(questionSlug);
  const currentQuestionIndex = currentQuestionNumber - 1;
  const currentQuestion = questions[currentQuestionIndex];
  const currentAnswer = currentQuestion?.answer || '';

  const handleClickPrev = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    const prevQuestionNumber = currentQuestionNumber - 1;
    history.push(`/gesprekshulp/${result.tool.slug}/${result.survey.slug}/${prevQuestionNumber}/${result.survey.key}`);
  };

  const handleClickNext = (event) => {
    event.preventDefault();
    event.currentTarget.blur();

    // skip answer but set answer key
    if (!currentAnswer.length) {
      const answerObject = {
        themeId: currentQuestion.theme.id,
        topicId: currentQuestion.topic.id,
        questionId: currentQuestion.id,
        answer: currentQuestion.type === 'yes_no' ? 'N.v.t.' : '',
      };
      dispatch(surveyActions.answerQuestion(answerObject));
    }

    if (currentQuestionNumber < questions.length) {
      const nextQuestionNumber = currentQuestionNumber + 1;
      history.push(
        `/gesprekshulp/${result.tool.slug}/${result.survey.slug}/${nextQuestionNumber}/${result.survey.key}`
      );
    } else {
      history.push(`/gesprekshulp/${result.tool.slug}/${result.survey.slug}/bevestigen/${result.survey.key}`);
    }
  };

  return (
    <SplitPane
      left={
        <React.Fragment>
          <div className="c-questions-nav">
            {currentQuestionNumber > 1 && (
              <Button label="Vorige" onClick={handleClickPrev} style="white" arrow="left" />
            )}
            <div className="c-questions-nav__index">
              {currentQuestionNumber} van {questions.length}
            </div>
          </div>
          <div className="c-question-tags">
            <Tag text={currentQuestion.theme.title} style="white" />
            <Tag text={currentQuestion.topic.title} style="secondary" />
          </div>
          <div>
            <h1 className="c-question-title">{currentQuestion.question}</h1>
            <p>{currentQuestion.info}</p>
          </div>
        </React.Fragment>
      }
      right={
        <React.Fragment>
          <BreadCrumb data={result} />
          <QuestionFormField question={currentQuestion} />
          <Button
            type="submit"
            label={currentAnswer.length ? 'Volgende' : 'Overslaan'}
            onClick={handleClickNext}
            arrow="right"
          />
        </React.Fragment>
      }
    />
  );
};

export default SurveyQuestion;
