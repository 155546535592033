import { ApiManager } from './apiUtils';

const apiManager = new ApiManager();

const API_SURVEY_TOOLS = `${process.env.REACT_APP_API_ENDPONT}/rest/tools`;

export function getTools(drafts = null) {
  const url = `${API_SURVEY_TOOLS}${drafts ? '?preview':''}`;

  return apiManager.get(url).then((data) => data);
}
