// react
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

// actions
import * as surveyActions from '../actions/surveyActions';

// components
import Loading from './Loading';
import ThemeProvider from './ThemeProvider';

// utils
import { debounce } from '../utils';

const SurveyPageWrapper = ({ background = false, center = false, fullWidth = false, container = true, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { toolSlug, surveySlug, surveyKey } = useParams();
  const survey = useSelector((state) => state.survey);

  const saveStateToLocalStorage = useCallback(
    debounce((surveyState) => {
      localStorage.setItem(`jogg_conversation_${surveyKey}`, JSON.stringify(surveyState));
    }),
    []
  );

  useEffect(() => {
    const localData = JSON.parse(window.localStorage.getItem(`jogg_conversation_${surveyKey}`));

    if (!survey.result) {
      if (localData) {
        dispatch(surveyActions.setConversationFromLocalStorage(localData));
      } else {
        dispatch(surveyActions.fetchSurvey({ toolSlug, surveySlug, surveyKey })).catch((error) => {
          if (error === 'key.invalid') {
            history.push('/niet-gevonden');
          }
        });
      }
    } else {
      if (survey.moderator.length || survey.participants.length) {
        saveStateToLocalStorage(survey);
      }
    }

    if (background && survey.result) {
      document.body.classList.add('bg-primary');
    }

    return () => {
      document.body.classList.remove('bg-primary');
    };
  }, [survey.participants, survey.moderator, survey.result]);

  return (
    <Loading isLoading={(survey.pending || !survey.result && !survey.error)} hasError={survey.error} fullScreen>
      <ThemeProvider selectedTheme={survey.result?.tool?.theme}>
        <section className={`o-content ${center ? 'o-content--center' : ''} ${fullWidth ? 'o-content--fullwidth' : ''}`}>
          {container ? (
            <div className="o-container">
                {children}
            </div>
          ) : (
            <React.Fragment>
            {children}
            </React.Fragment>
          )}
        </section>
      </ThemeProvider>
    </Loading>
  );
};

export default SurveyPageWrapper;
