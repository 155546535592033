import { ApiManager } from './apiUtils';

const apiManager = new ApiManager();

const API_SURVEY_DASHBOARD = `${process.env.REACT_APP_API_ENDPONT}/rest/dashboard/`;

export function getDashboard(params) {
  const { toolSlug, surveySlug, surveyKey } = params;

  const url = `${API_SURVEY_DASHBOARD}/${toolSlug}/${surveySlug}/${surveyKey}`;

  return apiManager.get(url).then((data) => data);
}
