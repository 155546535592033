// react
import React from 'react';

const TextareaFieldComponent = ({ name, label, value = '', placeholder, onChange }) => {
  return (
    <React.Fragment>
      <div className="c-form-group">
        <label className="c-form-label" htmlFor={name}>
          {label}
        </label>
        <textarea
          className="c-form-input"
          name={name}
          id={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </React.Fragment>
  );
};

export default TextareaFieldComponent;
