// TOOLS
export const FETCH_TOOLS_PENDING = 'FETCH_TOOLS_PENDING';
export const FETCH_TOOLS_SUCCESS = 'FETCH_TOOLS_SUCCESS';
export const FETCH_TOOLS_ERROR = 'FETCH_TOOLS_ERROR';

// SURVEY
export const FETCH_SURVEY_PENDING = 'FETCH_SURVEY_PENDING';
export const FETCH_SURVEY_SUCCESS = 'FETCH_SURVEY_SUCCESS';
export const FETCH_SURVEY_ERROR = 'FETCH_SURVEY_ERROR';

// CONVERSATION
export const START_CONVERSATION = 'START_CONVERSATION';
export const UPDATE_MODERATOR = 'UPDATE_MODERATOR';
export const UPDATE_PARTICIPANTS = 'UPDATE_PARTICIPANTS';
export const ANSWER_QUESTION = 'ANSWER_QUESTION';
export const SAVE_CONVERSATION = 'SAVE_CONVERSATION';
export const SAVE_CONVERSATION_PENDING = 'SAVE_CONVERSATION_PENDING';
export const SAVE_CONVERSATION_ERROR = 'SAVE_CONVERSATION_ERROR';
export const SAVE_CONVERSATION_SUCCESS = 'SAVE_CONVERSATION_SUCCESS';
export const SET_CONVERSATION_FROM_LOCAL_STORAGE = 'SET_CONVERSATION_FROM_LOCAL_STORAGE';

// DASHBOARD
export const FETCH_DASHBOARD_PENDING = 'FETCH_DASHBOARD_PENDING';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_ERROR = 'FETCH_DASHBOARD_ERROR';
