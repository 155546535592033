// react
import React from 'react';

const ButtonLinkComponent = ({ onClick, label, type = 'button', style = '', children }) => {
  let buttonClass = ['c-btn-link'];

  // add style
  switch (style.toLowerCase()) {
    case 'white':
      buttonClass.push('c-btn-link--white');
      break;
  }

  // join all classes
  buttonClass = buttonClass.join(' ');

  return (
    <React.Fragment>
      <button onClick={onClick} type={type} className={buttonClass}>
        {children ? children : label}
      </button>
    </React.Fragment>
  );
};

export default ButtonLinkComponent;
