// react
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// actions
import * as dashboardActions from '../actions/dashboardActions';

// components
import DashboardHeader from '../components/DashboardHeader';
import DashboardTabs from '../components/DashboardTabs';
import Loading from '../components/Loading';
import ThemeProvider from '../components/ThemeProvider';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { toolSlug, surveySlug, surveyKey } = useParams();
  const dashboard = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(dashboardActions.fetchDashboard({ toolSlug, surveySlug, surveyKey })).catch((error) => {
      if (error === 'key.invalid') {
        history.push('/niet-gevonden');
      }
    });
  }, []);

  return (
    <Loading isLoading={dashboard.pending} hasError={dashboard.error} fullScreen>
      <ThemeProvider selectedTheme={dashboard.data?.tool?.theme}>
          {dashboard.data && (
            <React.Fragment>
              <DashboardHeader dashboard={dashboard.data} />
              <DashboardTabs dashboard={dashboard.data} />
            </React.Fragment>
          )}
      </ThemeProvider>
    </Loading>
  );
};

export default DashboardPage;
