// react
import React  from 'react';
import { useSelector } from 'react-redux';

// components
import SurveyPageWrapper from '../components/SurveyPageWrapper';
import SurveyIntro from '../components/SurveyIntro';

const SurveyIntroPage = () => {
  const survey = useSelector((state) => state.survey);

  return (
    <SurveyPageWrapper background center>
        <SurveyIntro result={survey.result} />
    </SurveyPageWrapper>
  );
};

export default SurveyIntroPage;
