// react
import React from 'react';

const BreadCrumb = ({ data }) => {
  return (
    <div className="c-breadcrumb">
      Gesprekshulp {data.tool.title} - <strong>{data.survey.title}</strong>
    </div>
  );
};

export default BreadCrumb;
