import { combineReducers } from 'redux';

import toolsReducer from './toolsReducer';
import surveyReducer from './surveyReducer';
import dashboardReducers from './dashboardReducers';

const rootReducer = combineReducers({
  tools: toolsReducer,
  survey: surveyReducer,
  dashboard: dashboardReducers,
});

export default rootReducer;
