import * as types from '../actions/actionTypes';

const initialState = {
  results: [],
  pending: false,
  error: null,
};

export default function toolsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TOOLS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.FETCH_TOOLS_SUCCESS:
      return {
        ...state,
        pending: false,
        results: action.tools,
        error: null
      };
    case types.FETCH_TOOLS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
