// react
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// actions
import * as surveyActions from '../actions/surveyActions';

// components
import BreadCrumb from '../components/BreadCrumb';
import { Dialog, DialogTitle, DialogText } from '../components/Dialog';
import Button from '../components/Button';
import ButtonLink from '../components/ButtonLink';

const ConversationConfirm = ({ survey }) => {
  if (!survey.result) return null;

  const dispatch = useDispatch();
  const history = useHistory();

  const [dialogActive, setDialogActive] = useState(false);
  const [isDraftDialogActive, setIsDraftDialogActive] = useState(false);

  const handlePageBack = (event) => {
    event.preventDefault();
    event.currentTarget.blur();

    const questions = survey.result.questionnaire || [];

    history.push(
      `/gesprekshulp/${survey.result.tool.slug}/${survey.result.survey.slug}/${questions.length}/${survey.result.survey.key}`
    );
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    dispatch(surveyActions.fetchSaveConversation(survey))
      .then(() => {
        window.localStorage.removeItem(`jogg_conversation_${survey.result.survey.key}`);
        history.push(
          `/gesprekshulp/${survey.result.tool.slug}/${survey.result.survey.slug}/afgerond/${survey.result.survey.key}`
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  console.log();

  return (
    <React.Fragment>
      <div className="c-conversation-confirm">
        <BreadCrumb data={survey.result} />
        <div className="u-text-center u-text-white">
          <h1 className="c-conversation-confirm__title">We hebben alle thema’s en onderwerpen besproken</h1>
          <p className="c-conversation-confirm__text u-text-large">
            Ga terug om jullie antwoorden te wijzigen of sla de antwoorden op in de rapportage.
          </p>
          <div className="c-btn-group">
            <Button label="Wijzigen" onClick={handlePageBack} style="white-outline" arrow="left" />
            <Button label="Opslaan" onClick={() => survey.result.tool.isDraft ? setIsDraftDialogActive(true) : setDialogActive(true)} style="white" arrow="right" />
          </div>
        </div>
      </div>
      {dialogActive && (
        <Dialog handleClose={() => setDialogActive(false)}>
          <DialogTitle>Weet je zeker dat je alle antwoorden op wilt slaan?</DialogTitle>
          <DialogText>Na het opslaan kunnen er geen wijzigingen meer worden aangebracht</DialogText>
          <div className="c-btn-group">
            <ButtonLink onClick={handlePageBack} label="Nee, wijzigen" />
            <Button onClick={handleConfirm} label="Ja, opslaan" />
          </div>
        </Dialog>
      )}
      {isDraftDialogActive && (
        <Dialog handleClose={() => setIsDraftDialogActive(false)}>
          <DialogTitle>Dit gesprek kan niet worden opgeslagen!</DialogTitle>
          <DialogText>Deze gesprekshulp is een opzet en kan daarom niet worden opgeslagen.</DialogText>
          <div className="c-btn-group">
            <ButtonLink onClick={() => setIsDraftDialogActive(false)} label="Ik begrijp het" />
          </div>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default ConversationConfirm;
