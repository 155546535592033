// TODO: Optimize this code by using a debounce on change and find a better way to handle checkbox data

// react
import React from 'react';
import { useDispatch } from 'react-redux';

// actions
import * as surveyActions from '../actions/surveyActions';

// components
import TextareaField from './TextareaField';
import RadioGroup from './RadioGroup';
import CheckboxGroup from './CheckboxGroup';

const QuestionFormFieldComponent = ({ question, error }) => {
  const dispatch = useDispatch();

  const checkboxOptions = question?.options?.map((option) => ({
    value: option,
    label: option,
  }));

  const handleInputChange = (event) => {
    const value = event.target.value;
    handleDispatch(value);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const answers = question?.answer?.split(',') || [];
    let find = answers.indexOf(value);

    if (find > -1) {
      answers.splice(find, 1);
    } else {
      answers.push(value);
    }

    const sortedAnswers = answers.sort((a, b) => a - b);
    handleDispatch(sortedAnswers.join());
  };

  const handleDispatch = (value) => {
    const answerObject = {
      themeId: question.theme.id,
      topicId: question.topic.id,
      questionId: question.id,
      answer: value,
    };

    dispatch(surveyActions.answerQuestion(answerObject));
  };

  return (
    <React.Fragment>
      {
        {
          open: (
            <TextareaField
              name="question-textarea"
              label="Het antwoord"
              placeholder="Hou het antwoord zo kort en precies mogelijk"
              value={question?.answer || ''}
              onChange={handleInputChange}
              error={error}
            />
          ),
          yes_no: (
            <RadioGroup
              name="question-boolean"
              groupLabel="Selecteer één antwoord:"
              value={question?.answer || ''}
              onChange={handleInputChange}
              error={error}
            />
          ),
          multiple_choice: (
            <CheckboxGroup
              name="question-checkboxes"
              groupLabel="Selecteer één of meerdere antwoorden"
              options={checkboxOptions}
              value={question?.answer?.split(',') || []}
              onChange={handleCheckboxChange}
              error={error}
            />
          ),
        }[question.type]
      }
    </React.Fragment>
  );
};

export default QuestionFormFieldComponent;
