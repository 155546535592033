import * as types from '../actions/actionTypes';

const initialState = {
  result: null,
  moderator: '',
  participants: '',
  pending: false,
  error: null,
};

export default function surveyReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_SURVEY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.FETCH_SURVEY_SUCCESS:
      return {
        ...state,
        pending: false,
        result: action.survey,
        error: null
      };
    case types.FETCH_SURVEY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case types.UPDATE_MODERATOR:
      return {
        ...state,
        moderator: action.payload,
      };
    case types.UPDATE_PARTICIPANTS:
      return {
        ...state,
        participants: action.payload,
      };
    case types.ANSWER_QUESTION:
      return {
        ...state,
        result: {
          ...state.result,
          questionnaire: state.result.questionnaire.map((question) => {
            if (
              action.payload.themeId === question.theme.id &&
              action.payload.topicId === question.topic.id &&
              action.payload.questionId === question.id
            ) {
              return {...question, answer: action.payload.answer};
            }
            return question;
          }),
        },
      };
    case types.SAVE_CONVERSATION_SUCCESS:
      return initialState;
    case types.SET_CONVERSATION_FROM_LOCAL_STORAGE:
      return action.payload;
    default:
      return state;
  }
}
