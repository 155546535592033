// react
import React from 'react';

const tagComponent = ({ text, style = '' }) => {
  let tagClass = ['c-tag'];

  // add style
  switch (style.toLowerCase()) {
    case 'white':
      tagClass.push('c-tag--white');
      break;
    case 'secondary':
      tagClass.push('c-tag--secondary');
      break;
  }

  // join all classes
  tagClass = tagClass.join(' ');

  return <div className={tagClass}>{text}</div>;
};

export default tagComponent;
