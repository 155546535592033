// react
import React from 'react';

const InputFieldComponent = ({ name, label, value = '', placeholder, onChange, error = '' }) => {
  return (
    <React.Fragment>
      <div className={`c-form-group ${error && 'has-error'}`}>
        <label className="c-form-label" htmlFor={name}>
          {label}
        </label>
        <input
          className="c-form-input"
          type="text"
          name={name}
          id={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        {error && <div className="c-form-error">{error}</div>}
      </div>
    </React.Fragment>
  );
};

export default InputFieldComponent;
