// react
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';


// containers
import CreateSurveyPage from './CreateSurveyPage';
import SurveyIntroPage from './SurveyIntroPage';
import ConversationParticipantsPage from './ConversationParticipantsPage';
import ConversationAgendaPage from './ConversationAgendaPage';
import ConversationConfirmPage from './ConversationConfirmPage';
import ConversationFinishPage from './ConversationFinishPage';
import QuestionPage from './QuestionPage';
import NotFoundPage from './NotFoundPage';
import DashboardPage from './DashboardPage';

// components
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';

const Page = () => {
  return (
    <HelmetProvider>
      <Router>
        <React.Fragment>
          <main className="o-main">
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={CreateSurveyPage}></Route>
              <Route
                exact
                path="/gesprekshulp/:toolSlug/:surveySlug/deelnemers/:surveyKey"
                component={ConversationParticipantsPage}
              ></Route>
              <Route
                exact
                path="/gesprekshulp/:toolSlug/:surveySlug/agenda/:surveyKey"
                component={ConversationAgendaPage}
              ></Route>
              <Route
                exact
                path="/gesprekshulp/:toolSlug/:surveySlug/bevestigen/:surveyKey"
                component={ConversationConfirmPage}
              ></Route>
              <Route
                exact
                path="/gesprekshulp/:toolSlug/:surveySlug/afgerond/:surveyKey"
                component={ConversationFinishPage}
              ></Route>
              <Route
                exact
                path="/gesprekshulp/:toolSlug/:surveySlug/:questionSlug/:surveyKey"
                component={QuestionPage}
              ></Route>
              <Route exact path="/gesprekshulp/:toolSlug/:surveySlug/:surveyKey" component={SurveyIntroPage}></Route>
              <Route exact path="/dashboard/:toolSlug/:surveySlug/:surveyKey" component={DashboardPage}></Route>
              <Route component={NotFoundPage} />
            </Switch>
          </main>
          <Footer />
        </React.Fragment>
      </Router>
    </HelmetProvider>
  );
};

export default Page;
