import * as types from './actionTypes';
import * as surveyApi from '../api/surveyApi';

function fetchSurveyPending() {
  return {
    type: types.FETCH_SURVEY_PENDING,
  };
}

function fetchSurveySuccess(survey) {
  return {
    type: types.FETCH_SURVEY_SUCCESS,
    survey,
  };
}

function fetchSurveyError(error) {
  return {
    type: types.FETCH_SURVEY_ERROR,
    error,
  };
}

export function startConversation() {
  return {
    type: types.START_CONVERSATION,
  };
}

export function updateModerator(input) {
  return {
    type: types.UPDATE_MODERATOR,
    payload: input,
  };
}

export function updateParticipants(input) {
  return {
    type: types.UPDATE_PARTICIPANTS,
    payload: input,
  };
}

export function answerQuestion(answer) {
  return {
    type: types.ANSWER_QUESTION,
    payload: answer,
  };
}

export function saveConversation(conversation) {
  return {
    type: types.SAVE_CONVERSATION,
    payload: conversation,
  };
}

function saveConversationPending() {
  return {
    type: types.SAVE_CONVERSATION_PENDING,
  };
}

function saveConversationError(error) {
  return {
    type: types.SAVE_CONVERSATION_ERROR,
    error,
  };
}

function saveConversationSuccess() {
  return {
    type: types.SAVE_CONVERSATION_SUCCESS,
  };
}

export function setConversationFromLocalStorage(surveyState) {
  return {
    type: types.SET_CONVERSATION_FROM_LOCAL_STORAGE,
    payload: surveyState,
  };
}

export function fetchSurvey(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(fetchSurveyPending());
      return surveyApi
        .getSurvey(params)
        .then((data) => {
          if (data?.success && data?.survey) {
            dispatch(fetchSurveySuccess(data.survey));
            resolve(data.survey);
          } else {
            dispatch(fetchSurveyError(data.code));
            reject(data.code);
            throw data.code;
          }
        })
        .catch((error) => {
          dispatch(fetchSurveyError(error));
          throw error;
        });
    });
  };
}

export function fetchCreateSurvey(body) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(fetchSurveyPending());
      return surveyApi
        .postCreateSurvey(body)
        .then((data) => {
          if (data?.success && data?.survey) {
            dispatch(fetchSurveySuccess(data.survey));
            resolve(data.survey);
          } else {
            dispatch(fetchSurveyError(data.code));
            reject(data.code);
            throw data.code;
          }
        })
        .catch((error) => {
          dispatch(fetchSurveyError(error));
          reject();
          throw error;
        });
    });
  };
}

export function fetchSaveConversation(conversation) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(saveConversationPending());
      return surveyApi
        .postSaveConversation(conversation)
        .then((data) => {
          dispatch(saveConversationSuccess(data));
          resolve(data);
        })
        .catch((error) => {
          dispatch(saveConversationError(error));
          reject();
          throw error;
        });
    });
  };
}
