// react
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// components
import SurveyPageWrapper from '../components/SurveyPageWrapper';
import SurveyQuestion from '../components/SurveyQuestion';

const QuestionPage = () => {
  const history = useHistory();

  const { toolSlug, surveySlug, surveyKey, questionSlug } = useParams();
  const survey = useSelector((state) => state.survey);

  const questions = survey.result?.questionnaire || [];
  const currentQuestionNumber = Number(questionSlug);
  const currentQuestionIndex = currentQuestionNumber - 1;
  const questionsFilled = questions.filter((question) => question.answer !== undefined).length;

  useEffect(() => {
    if (survey.result) {
      if (!survey.moderator.length || !survey.participants.length) {
        history.push(`/gesprekshulp/${toolSlug}/${surveySlug}/${surveyKey}`);
      } else if (isNaN(questionSlug) || currentQuestionIndex <= -1 || currentQuestionIndex >= questionsFilled) {
        history.push(`/gesprekshulp/${toolSlug}/${surveySlug}/${questionsFilled + 1}/${surveyKey}`);
      }
    }
  }, [survey]);

  return (
    <SurveyPageWrapper fullWidth container={false}>
      <SurveyQuestion result={survey.result} />
    </SurveyPageWrapper>
  );
};

export default QuestionPage;
