import * as types from './actionTypes';
import * as toolsApi from '../api/toolsApi';

function fetchToolsPending() {
  return {
    type: types.FETCH_TOOLS_PENDING,
  };
}

function fetchToolsSuccess(tools) {
  return {
    type: types.FETCH_TOOLS_SUCCESS,
    tools,
  };
}

function fetchToolsError(error) {
  return {
    type: types.FETCH_TOOLS_ERROR,
    error,
  };
}

export function fetchTools(drafts) {
  return function (dispatch) {
    dispatch(fetchToolsPending());

    return toolsApi
      .getTools(drafts)
      .then((data) => {
        if (data?.success && data?.tools) {
          dispatch(fetchToolsSuccess(data.tools));
        } else {
          dispatch(fetchToolsError(data?.message));
          throw data?.message;
        }
      })
      .catch((error) => {
        dispatch(fetchToolsError(error));
        throw error;
      });
  };
}
