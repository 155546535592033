// react
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// actions
import * as surveyActions from '../actions/surveyActions';

// components
import InputField from './InputField';
import Button from './Button';
import SplitPane from './SplitPane';
import BreadCrumb from './BreadCrumb';
import AlertMessage from './AlertMessage';

const ParticipantsForm = ({ survey }) => {
  if (!survey.result) return null;

  const dispatch = useDispatch();
  const history = useHistory();
  const [formErrors, setFormErrors] = useState({ moderator: '', participants: '' });

  const questions = survey.result.questionnaire;
  if (!questions.length) {
    return <AlertMessage type="error" message="De vragenlijst van dit gesprek is leeg of bevat een fout. Neem contact met ons op." />;
  }

  const handleModeratorInput = (event) => {
    dispatch(surveyActions.updateModerator(event.target.value));

    setFormErrors((prevState) => ({
      ...prevState,
      moderator: '',
    }));
  };

  const handleParticipantsInput = (event) => {
    dispatch(surveyActions.updateParticipants(event.target.value));

    setFormErrors((prevState) => ({
      ...prevState,
      participants: '',
    }));
  };

  const handleValidation = () => {
    let formIsValid = true;

    if (!survey.moderator.length) {
      formIsValid = false;
      setFormErrors((prevState) => ({
        ...prevState,
        moderator: 'vul de naam van de gespreksleider in',
      }));
    }

    if (!survey.participants.length) {
      formIsValid = false;
      setFormErrors((prevState) => ({
        ...prevState,
        participants: 'vul de (team)naam of namen van de deelnemer(s) in',
      }));
    }

    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.currentTarget.blur();

    if (handleValidation()) {
      history.push(
        `/gesprekshulp/${survey.result.tool.slug}/${survey.result.survey.slug}/agenda/${survey.result.survey.key}`
      );
    } else {
      console.error('Please check all fields.');
    }
  };

  return (
    <SplitPane
      left={
        <React.Fragment>
          <h1>Welkom, wat goed dat iedereen er is!</h1>
          <p>
          Voor we het gesprek starten, is het belangrijk dat iedereen elkaar leert kennen. Vul jullie namen in.
          </p>
        </React.Fragment>
      }
      right={
        <React.Fragment>
          <BreadCrumb data={survey.result} />
          <form className="c-form">
            <InputField
              name="moderator"
              label="Wie is de gespreksleider?"
              placeholder="Typ naam"
              value={survey.moderator}
              onChange={handleModeratorInput}
              error={formErrors.moderator}
            />
            <InputField
              name="participants"
              label="Wie is de deelnemer / zijn de deelnemers?"
              placeholder="Typ naam of namen"
              value={survey.participants}
              onChange={handleParticipantsInput}
              error={formErrors.participants}
            />
            <Button type="submit" label="Uitleg & agenda" onClick={handleSubmit} arrow="right" />
          </form>
        </React.Fragment>
      }
    />
  );
};

export default ParticipantsForm;
