// react
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// components
import SurveyPageWrapper from '../components/SurveyPageWrapper';
import SurveyAgenda from '../components/SurveyAgenda';

const ConversationAgendaPage = () => {
  const history = useHistory();

  const { toolSlug, surveySlug, surveyKey } = useParams();
  const survey = useSelector((state) => state.survey);

  useEffect(() => {
    if (survey.result) {
      if (!survey.moderator.length || !survey.participants.length) {
        history.push(`/gesprekshulp/${toolSlug}/${surveySlug}/${surveyKey}`);
      }
    }
  }, []);

  return (
    <SurveyPageWrapper fullWidth container={false}>
      <SurveyAgenda result={survey.result} />
    </SurveyPageWrapper>
  );
};

export default ConversationAgendaPage;
