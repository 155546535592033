// react
import React from 'react';

const CheckboxField = ({ id, name, value, label, onChange, checkedItems }) => {
  return (
    <div className="c-form-checkbox">
      <input
        name={name}
        id={id}
        className="c-form-checkbox__input"
        type="checkbox"
        value={value}
        onChange={onChange}
        checked={checkedItems.includes(value.toString())}
      />
      <label className="c-form-checkbox__label" htmlFor={id}>
        <div className="c-form-checkbox__control"></div>
        {label}
      </label>
    </div>
  );
};

const CheckboxGroupComponent = ({ name, groupLabel, options, onChange, value }) => {
  return (
    <div className="c-form-group">
      <label className="c-form-label">{groupLabel}</label>
      {options?.map((option, index) => {
        const key = `${name}_${index + 1}`;
        return (
          <CheckboxField
            name={name}
            key={key}
            id={key}
            value={index}
            label={option.label}
            checkedItems={value}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
};

export default CheckboxGroupComponent;
