// react
import React from 'react';

// assets
import { ReactComponent as ArrowIcon } from '../assets/images/icons/arrow.svg';

const ButtonComponent = ({
  onClick,
  label,
  type = 'button',
  disabled = false,
  arrow = '',
  style = '',
  children
}) => {
  let buttonClass = ['c-btn'];

  // add style
  switch (style.toLowerCase()) {
    case 'white':
      buttonClass.push('c-btn--white');
      break;
    case 'white-outline':
      buttonClass.push('c-btn--white-outline');
      break;
    default:
      break;
  }

  // add disabled state
  if (disabled) {
    buttonClass.push('is-disabled');
  }

  // join all classes
  buttonClass = buttonClass.join(' ');

  let ButtonContent = label;

  if (arrow.toLowerCase() === 'left') {
    ButtonContent = (
      <React.Fragment>
        <ArrowIcon className="c-arrow-icon" />
        <span>{label}</span>
      </React.Fragment>
    );
  }

  if (arrow.toLowerCase() === 'right') {
    ButtonContent = (
      <React.Fragment>
        <span>{label}</span>
        <ArrowIcon className="c-arrow-icon" />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <button onClick={onClick} type={type} className={buttonClass} disabled={disabled}>
        {children ? children : ButtonContent}
      </button>
    </React.Fragment>
  );
};

export default ButtonComponent;
