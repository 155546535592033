// react
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// actions
import * as surveyActions from '../actions/surveyActions';

// components
import Button from './Button';
import InputField from './InputField';
import RadioGroup from './RadioGroup';

const GenerateSurveyFromComponent = ({ tools }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [surveyTitle, setSurveyTitle] = useState();
  const [toolSlug, setToolSlug] = useState();
  const [disableNext, setDisableNext] = useState(true);
  const [formErrors, setFormErrors] = useState({ toolSlug: '', surveyTitle: '' });

  useEffect(() => {
    if (surveyTitle && toolSlug) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
  }, [surveyTitle, toolSlug]);

  const handleInput = (e) => {
    setSurveyTitle(e.target.value);
    setFormErrors((prevState) => ({
      ...prevState,
      surveyTitle: '',
    }));
  };

  const handleRadioChange = (e) => {
    setToolSlug(e.target.value);
    setFormErrors((prevState) => ({
      ...prevState,
      surveyTitle: surveyTitle ? '' : prevState.surveyTitle,
      toolSlug: '',
    }));
  };

  const handleValidation = () => {
    let formIsValid = true;

    if (!toolSlug) {
      formIsValid = false;
      setFormErrors((prevState) => ({
        ...prevState,
        toolSlug: 'selecteer een gesprekshulp',
      }));
    }

    if (!surveyTitle) {
      formIsValid = false;
      setFormErrors((prevState) => ({
        ...prevState,
        surveyTitle: 'vul een titel in voor het gesprek',
      }));
    }

    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.currentTarget.blur();

    if (handleValidation()) {
      const body = {
        toolSlug,
        title: surveyTitle,
      };

      dispatch(surveyActions.fetchCreateSurvey(body))
        .then((response) => {
          const { slug: surveySlug, key: surveyKey } = response.survey;
          history.push(`/gesprekshulp/${toolSlug}/${surveySlug}/${surveyKey}`);
        })
        .catch((error) => {
          if (error === 'error.page.draft.duplicate') {
            setFormErrors((prevState) => ({
              ...prevState,
              surveyTitle: 'Er bestaat al een gesprek met deze naam. Vraag de gesprekslink aan binnen de organisatie of bedenk een andere naam.',
            }));
            setDisableNext(true);
          }
        });
    } else {
      setDisableNext(true);
      console.error('Please check all fields.');
    }
  };

  const radioGroupOptions = tools.map((tool) => ({
    value: tool.slug,
    label: tool.title,
  }));

  return (
    <form className="c-form c-create-survey-form">
      <RadioGroup
        name="tool-slug"
        groupLabel="Kies een onderwerp"
        options={radioGroupOptions}
        onChange={handleRadioChange}
        value={toolSlug}
        error={formErrors.toolSlug}
      />
      <InputField
        name="survey-title"
        label="Vul de naam van jouw gemeente of organisatie in"
        placeholder="Typ hier..."
        value={surveyTitle}
        onChange={handleInput}
        error={formErrors.surveyTitle}
      />
      <Button type="submit" label="Maak link aan" arrow="right" onClick={handleSubmit} disabled={disableNext} />
    </form>
  );
};

export default GenerateSurveyFromComponent;
