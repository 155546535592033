// react
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

// utils
import { randomizeArray } from '../utils';

// assets
import { ReactComponent as IllustrationFace1 } from '../assets/images/illustration-face-1.svg';
import { ReactComponent as IllustrationFace2 } from '../assets/images/illustration-face-2.svg';
import { ReactComponent as IllustrationFace3 } from '../assets/images/illustration-face-3.svg';
import { ReactComponent as IllustrationFace4 } from '../assets/images/illustration-face-4.svg';
import { ReactComponent as IllustrationFace5 } from '../assets/images/illustration-face-5.svg';
import { ReactComponent as IllustrationFace6 } from '../assets/images/illustration-face-6.svg';

const SplitPane = ({ right, left }) => {
  const location = useLocation();

  const shuffledFaces = useMemo(() => {
    const faces = [
      <IllustrationFace1 key="face-1" />,
      <IllustrationFace2 key="face-2" />,
      <IllustrationFace3 key="face-3" />,
      <IllustrationFace4 key="face-4" />,
      <IllustrationFace5 key="face-5" />,
      <IllustrationFace6 key="face-6" />,
    ];
    return randomizeArray(faces);
  }, [location]);

  useEffect(() => {
    document.body.classList.add('is-splitted');

    return () => {
      document.body.classList.remove('is-splitted');
    };
  }, []);

  return (
    <div className="c-split-pane">
      <div className="c-split-pane-left bg-primary u-text-white">
        <div className="c-split-pane-container">
          {left}
          <div className="c-split-pane-illustration">{shuffledFaces}</div>
        </div>
      </div>
      <div className="c-split-pane-right">
        <div className="c-split-pane-container">{right}</div>
      </div>
    </div>
  );
};

export default SplitPane;
