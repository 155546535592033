import * as types from './actionTypes';
import * as dashboardApi from '../api/dashboardApi';

function fetchDashboardPending() {
  return {
    type: types.FETCH_DASHBOARD_PENDING,
  };
}

function fetchDashboardSuccess(dashboardData) {
  return {
    type: types.FETCH_DASHBOARD_SUCCESS,
    data: dashboardData,
  };
}

function fetchDashboardError(error) {
  return {
    type: types.FETCH_DASHBOARD_ERROR,
    error,
  };
}

export function fetchDashboard(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(fetchDashboardPending());
      return dashboardApi
        .getDashboard(params)
        .then((data) => {
          if (data?.success && data?.dashboardData) {
            dispatch(fetchDashboardSuccess(data.dashboardData));
            resolve(data.dashboardData);
          } else {
            dispatch(fetchDashboardError(data.code));
            reject(data.code);
            throw data.code;
          }
        })
        .catch((error) => {
          dispatch(fetchDashboardError(error));
          throw error;
        });
    });
  };
}
