// react
import React, { useState } from 'react';

// vendors
import copy from 'copy-to-clipboard';

// components
import ButtonLink from './ButtonLink';
import Button from './Button';

// assets
import { ReactComponent as CopyIcon } from '../assets/images/icons/copy.svg';
import { ReactComponent as CheckmarkIcon } from '../assets/images/icons/checkmark.svg';

const CopyUrlButton = ({ value, label, link = false }) => {
  const [copyed, setCopy] = useState();

  const handleClick = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    const originPath = window.location.origin;
    const url = `${originPath}${value}`;
    copy(url);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  if (link) {
    return (
      <ButtonLink onClick={handleClick} style="white">
        <span>{label}</span>
        <div className={`c-copy-feedback ${copyed ? 'show' : ''}`}>
          <CheckmarkIcon />
          <span>link is gekopieerd!</span>
        </div>
      </ButtonLink>
    );
  } else {
    return (
      <Button onClick={handleClick} style="white" copyIcon>
        <span>{label}</span>
        <CopyIcon className="c-copy-icon" />
        <div className={`c-copy-feedback ${copyed ? 'show' : ''}`}>
          <CheckmarkIcon />
          <span>link is gekopieerd!</span>
        </div>
      </Button>
    );
  }
};

export default CopyUrlButton;
