// react
import React, { useState } from 'react';

// vendors
import { Bar } from 'react-chartjs-2';

// assets
import { ReactComponent as SwipeIcon } from '../assets/images/icons/swipe.svg';

function countOccurrence(array, value) {
  return array.filter((v) => v.toLowerCase() === value.toLowerCase()).length;
}

const BarChartComponent = ({ question }) => {
  const [showOverlay, setShowOverlay] = useState(true);

  const amountOfAnswers = question.answers.length;

  // split answers into array
  const answers = question.answers.map((answer) => {
    return answer.answer.split(',');
  });

  // merge all arrays into one
  const mergedAnswers = [].concat.apply([], answers);

  // count given answers per question
  const answersData = question.options.map((option, index) => {
    const itemCount = countOccurrence(mergedAnswers, index.toString());
    const percentage = ((itemCount * 100) / amountOfAnswers).toFixed(2).replace(/\.?0+$/, '');
    return percentage;
  });

  const labels = question.options;

  var style = getComputedStyle(document.body);
  var textColor = style.getPropertyValue('--color-text');
  var primaryColor = style.getPropertyValue('--color-primary');

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Participanten (%)',
        data: answersData,
        backgroundColor: primaryColor,
        hoverBackgroundColor: primaryColor,
        borderWidth: 0,
        borderRadius: 3,
        maxBarThickness: 38,
      },
    ],
  };

  const options = {
    responsive: true,
    aspectRatio: 2.5,
    indexAxis: 'y',
    scales: {
      y: {
        grid: {
          color: 'transparent',
          borderColor: 'transparent',
          tickColor: 'transparent',
        },
        ticks: {
          color: textColor,
          font: {
            family: "'Houschka', Arial",
            size: 15,
            lineHeight: 1.2,
          },
          callback: function (value) {
            const label = this.getLabelForValue(value);
            if (label.length > 25) {
              if (label.trim().split(/\s+/).length > 3) {
                return label.length > 25 ? label.substring(0, 25 - 3) + '...' : label;
              }
              return label.replace(/\s+/, '\n').split('\n');
            }
            return label;
          },
        },
        afterSetDimensions: function (axes) {
          axes.maxWidth = 195;
        },
      },
      x: {
        beginAtZero: true,
        min: 0,
        max: 100,
        grid: {
          color: '#d1d1d1',
          borderColor: 'transparent',
          tickColor: 'transparent',
        },
        ticks: {
          color: textColor,
          font: {
            family: "'Houschka', Arial",
            size: 15,
            lineHeight: 1.2,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
        onClick: null,
        labels: {
          boxWidth: 0,
          padding: 4.6,
          color: textColor,
          textAlign: 'right',
          font: {
            family: "'Houschka', Arial",
            size: 15,
            lineHeight: 1.2,
          },
        },
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context) {
            return `Aantal gekozen: ${((context.parsed.x / 100) * amountOfAnswers).toFixed(2).replace(/\.?0+$/, '')}`;
          },
        },
      },
    },
  };

  const handleClick = () => {
    setShowOverlay(false);
  };

  return (
    <React.Fragment>
      <div className="c-bar-chart" onTouchStart={handleClick} onClick={handleClick}>
        <div className={`c-bar-chart__overlay ${showOverlay ? '' : 'is-hidden'}`}>
          <div className="c-bar-chart__overlay-icon">
            <SwipeIcon />
          </div>
        </div>
        <div className="c-bar-chart__wrapper">
          <div className="c-bar-chart__wrapper-inner">
            <Bar className="c-bar-chart__chart" data={data} options={options} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BarChartComponent;
