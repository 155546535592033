// react
import React, { useRef } from 'react';

// vendors
import { Pie } from 'react-chartjs-2';

function countOccurrence(array, value) {
  return array.filter((v) => v.toLowerCase() === value.toLowerCase()).length;
}

function countUnique(iterable) {
  return new Set(iterable).size;
}

const PieChartComponent = ({ question }) => {
  const legend = useRef(null);

  const htmlLegendPlugin = {
    afterUpdate(chart) {
      const element = legend.current;

      while (element.firstChild) {
        element.firstChild.remove();
      }

      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      const amountOfAnswers = question.answers.length;

      items.forEach((item, index) => {
        const li = document.createElement('li');

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;

        const itemCount = chart.data.datasets[0].data[index];
        const percentage = ((itemCount * 100) / amountOfAnswers).toFixed(2).replace(/\.?0+$/, '') + '%';
        const text = document.createTextNode(`${item.text} (${percentage})`);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        element.appendChild(li);
      });
    },
  };

  const labels = ['Ja', 'Nee', 'N.v.t.'];

  const answersArray = question.answers.map((answer) => {
    return answer.answer;
  });

  const answersData = labels.map((label) => {
    return countOccurrence(answersArray, label);
  });

  var style = getComputedStyle(document.body);
  var textColor = style.getPropertyValue('--color-text');
  var primaryColor = style.getPropertyValue('--color-primary');
  var secondaryColor = style.getPropertyValue('--color-secondary');

  const data = {
    labels: labels,
    datasets: [
      {
        data: answersData,
        backgroundColor: [primaryColor, secondaryColor, textColor],
        hoverBackgroundColor: [primaryColor, secondaryColor, textColor],
        borderWidth: countUnique(answersArray) > 1 ? 3 : 0,
        hoverColor: '#ffffff',
        hoverBorderColor: '#ffffff',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const plugins = [htmlLegendPlugin];

  return (
    <React.Fragment>
      <div className="c-pie-chart">
        <Pie className="c-pie-chart__chart" data={data} options={options} plugins={plugins} />
        <ul className="c-pie-chart__legend" ref={legend}></ul>
      </div>
    </React.Fragment>
  );
};

export default PieChartComponent;
