// react
import React from 'react';

// assets
import { ReactComponent as CrossIcon } from '../assets/images/icons/cross.svg';

export const DialogTitle = ({ children }) => {
  return <h3 className="c-dialog-title">{children}</h3>;
};

export const DialogText = ({ children }) => {
  return <p className="c-dialog-text">{children}</p>;
};

export const Dialog = ({ children, handleClose }) => {
  return (
    <React.Fragment>
      <div className="c-dialog">
        <div className="c-dialog__inner">
          <div className="c-dialog-content">
            <button type="button" className="c-btn c-dialog-close" onClick={handleClose}>
              <CrossIcon />
            </button>
            {children}
          </div>
        </div>
      </div>
      <div className="c-dialog-backdrop"></div>
    </React.Fragment>
  );
};
