// react
import React from 'react';

// components
import AlertMessage from './AlertMessage';

const Loading = ({ isLoading, hasError, children, fullScreen }) => {
  if (hasError) {
    return <AlertMessage type="error" message="Er is een fout opgetreden. Probeer het later opnieuw" />;
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <div className={`c-loader-container ${fullScreen ? 'c-loader-container--fullscreen' : ''}`}>
          <div className="c-loader">
            <div className="c-loader__circle c-loader__circle--1"></div>
            <div className="c-loader__circle c-loader__circle--2"></div>
            <div className="c-loader__circle c-loader__circle--3"></div>
            <div className="c-loader__circle c-loader__circle--4"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (children) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return null;
};

export default Loading;
