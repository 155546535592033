// react
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import * as toolsActions from '../actions/toolsActions';

// components
import GenerateSurveyForm from '../components/GenerateSurveyForm';
import Loading from '../components/Loading';
import ThemeProvider from '../components/ThemeProvider';

// assets
import { ReactComponent as IllustrationConversation } from '../assets/images/illustration-conversation.svg';

const CreateSurveyPage = () => {
  const params = new URLSearchParams(window.location.search);
  const loadDrafts = params.has('preview');

  const dispatch = useDispatch();
  const tools = useSelector((state) => state.tools);
  let initialized = false;

  useEffect(() => {
    if (!tools.results.length && !initialized) {
      dispatch(toolsActions.fetchTools(loadDrafts));
      initialized = true;
    }
  }, [tools.results]);

  return (
    <ThemeProvider random>
      <section className="o-content">
        <div className="o-container">
          <div className="c-create-survey-intro">
            <IllustrationConversation className="c-create-survey-intro__illustration" />
            <h1>Tijd voor een goed gesprek</h1>
            <p className="c-create-survey-intro__text u-text-large">
              Structureer en rapporteer je inhoudelijke gesprekken met de JOGG-gesprekshulp.
            </p>
          </div>
          <Loading isLoading={tools.pending} hasError={tools.error}>
            <GenerateSurveyForm tools={tools.results} />
          </Loading>
        </div>
      </section>
    </ThemeProvider>
  );
};

export default CreateSurveyPage;
