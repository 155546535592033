// react
import React from 'react';
import { useHistory } from 'react-router';

// components
import Button from '../components/Button';
import ThemeProvider from '../components/ThemeProvider';

const NotFoundPage = () => {
  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    history.push(`/`);
  };

  return (
    <ThemeProvider random>
      <section className="o-content o-content--center">
        <div className="o-container">
          <div className="c-not-found u-text-center">
            <h1>Oeps, er ging iets mis!</h1>
            <p>
              We kunnen deze pagina helaas niet meer vinden. Het lijkt erop dat deze pagina niet (meer) bestaat of
              misschien verhuisd is.
            </p>
            <Button label="Maak een nieuw gesprek aan" arrow="left" onClick={handleClick} />
          </div>
        </div>
      </section>
    </ThemeProvider>
  );
};

export default NotFoundPage;
