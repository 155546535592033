// react
import React from 'react';
import { useHistory } from 'react-router';

// components
import Button from './Button';
import SplitPane from './SplitPane';
import BreadCrumb from './BreadCrumb';

const SurveyAgenda = ({ result }) => {
  if (!result) return null;

  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    history.push(`/gesprekshulp/${result.tool.slug}/${result.survey.slug}/1/${result.survey.key}`);
  };

  return (
    <SplitPane
      left={
        <React.Fragment>
          <h1>Zo werkt het</h1>
          <p className="u-text-large">
            Deze gesprekshulp bestaat uit een serie vragen, die binnen het gesprek beantwoord kunnen worden om zo aan het
            einde een samenvattende rapportage op te leveren.
          </p>
          <p>
            Wanneer een vraag niet relevant is, kan deze worden overgeslagen. Via de buttons is het mogelijk om naar de
            volgende of vorige vraag te gaan en het antwoord te wijzigen. Alle input wordt tijdens de sessie onthouden, maar
            pas aan het einde met jullie bevestiging echt opgeslagen en opgenomen in de rapportage.
          </p>
        </React.Fragment>
      }
      right={
        <React.Fragment>
          <BreadCrumb data={result} />
          <div className="c-themes">
            <h2 className="c-themes__title">De thema&apos;s</h2>
            <ul className="c-themes__list">
              {result.themes.map((thema) => (
                <li key={thema}>{thema}</li>
              ))}
            </ul>
          </div>
          <Button label="Start de vragen" onClick={handleClick} arrow="right" />
        </React.Fragment>
      }
    />
  );
};

export default SurveyAgenda;
