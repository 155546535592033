// react
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

// actions
import * as surveyActions from '../actions/surveyActions';

// components
import { Dialog, DialogTitle, DialogText } from '../components/Dialog';
import Button from './Button';
import ButtonLink from '../components/ButtonLink';
import CopyUrlButton from './CopyUrlButton';

const SurveyIntroComponent = ({ result }) => {
  if (!result) return null;

  const dispatch = useDispatch();
  const history = useHistory();

  const [dialogActive, setDialogActive] = useState(false);

  const handleStart = (event) => {
    event.preventDefault();
    event.currentTarget.blur();
    dispatch(surveyActions.startConversation());
    history.push(`/gesprekshulp/${result.tool.slug}/${result.survey.slug}/deelnemers/${result.survey.key}`);
  };

  return (
    <React.Fragment>
      <div className="c-survey-intro u-text-center u-text-white">
        <p className="c-survey-intro__pretitle">Je gesprek staat klaar</p>
        <p className="c-survey-intro__text u-text-large">Gesprekshulp {result.tool.title}</p>
        <h1 className="c-survey-intro__title">{result.survey.title}</h1>
        <div className="c-btn-group c-btn-group--list">
          <Button onClick={() => setDialogActive(true)} label="Start het gesprek" style="white" arrow="right" />
          <CopyUrlButton
            value={`/gesprekshulp/${result.tool.slug}/${result.survey.slug}/${result.survey.key}`}
            label="Kopieer gesprekslink"
            link
          />
        </div>
      </div>
      {dialogActive && (
        <Dialog handleClose={() => setDialogActive(false)}>
          <DialogTitle>Heb je de gesprekslink ergens opgeslagen?</DialogTitle>
          <DialogText>Het is niet mogelijk om nogmaals voor &apos;{ result.survey.title }&apos; een gesprek aan te maken. Daarom is het kopiëren van de gesprekslink heel belangrijk.</DialogText>
          <div className="c-btn-group">
            <ButtonLink onClick={() => setDialogActive(false)} label="Nee, ga terug" />
            <Button onClick={(e) => handleStart(e)} label="Ja, start het gesprek" />
          </div>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default SurveyIntroComponent;
