// react
import React from 'react';
import { useSelector } from 'react-redux';

// components
import SurveyPageWrapper from '../components/SurveyPageWrapper';
import ConversationFinish from '../components/ConversationFinish';

const ConversationFinishPage = () => {
  const survey = useSelector((state) => state.survey);

  return (
    <SurveyPageWrapper background center>
      <ConversationFinish result={survey.result} />
    </SurveyPageWrapper>
  );
};

export default ConversationFinishPage;
